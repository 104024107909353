<template>
  <vue-scroll class="terms-page align-vertical-middle ps">
    <div class="flex column gaps">
      <div class="box center">
        <h2 class="accent-text pb-0">QR code</h2>
      </div>
      <div class="box center fs-18 pb-10" style="width: 280px;" >Please press "Download" to get this QR code on your phone save time during check-in at the venue.</div>
      <div class="box center">
        <!-- <img :src="src" ref="qr" alt="RollDXB QR code"> -->
        <vue-qrcode ref="qr" :options="{ width: 280 }" :value="'rolldxb: ' + $route.params.id"></vue-qrcode>
      </div>
      <div ref="server"></div>
    <div class="box center mb-30 pb-20">
      <el-button type="primary" size="small" @click="download" class="signin-btn">
        DOWNLOAD IMAGE
      </el-button>
    </div>
    </div>

  </vue-scroll>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode'

export default {
  name: 'QR',

  components: {
    VueQrcode
  },
  data () {
    return {
      src: ''
    }
  },
  methods: {
    download () {
      let image = this.$refs.qr.$el.toDataURL()
      // let image = this.src
      let aDownloadLink = document.createElement('a')
      aDownloadLink.download = 'rolldxb-waiver.png'
      aDownloadLink.href = image
      document.body.appendChild(aDownloadLink)
      aDownloadLink.click()
    }
  },
  activated () {
    this.$nextTick(() => {
      this.$store.commit('setSplashScreen', false)
    })
  }
}
</script>

<style lang="scss">
@import '../assets/scss/_variables';

.terms-page {
  margin-left: -30px;
  margin-right: -30px;
}

@media (max-width: 768px) {
  .layout-container .container .view.terms-page {
    margin-left: -5px;
    margin-right: -5px;
    max-width: calc(100% + 10px);
  }
}
</style>
